import {ErrorHandler, Injectable, NgZone} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from '../environments/environment';

@Injectable()
export class VTErrorHandler implements ErrorHandler {

  constructor(private router: Router, private zone: NgZone) {}

  handleError(error: any) {
    console.error(error);
    // Check if it's an error from an HTTP response
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection; // get the error object
    }
    if (!environment.production) {
      console.error(`Error from global error handler`, error);
    }
    this.zone.run(() => {
      this.router.navigate(['/error'], { replaceUrl: true, preserveFragment: false, state: error });
    });
  }
}
