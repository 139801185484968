// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.base.ts` with `environmentBase.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const companyName: string = 'Veteran Technologies';
export const domain: string = 'veterantech.io';
export enum environments {
  DEV="dev",
  STAGING="stg",
  PROD="prd"
}
export const environment = {
  production: false,
  env: environments.DEV,
  region: 'us-east-1',
  domain,
  baseUrl: `https://${domain}`,
  baseHref: '/',
  baseAssetsHref: 'https://static.veterantech.io',
  businessMediaHref: 'https://static.veterantech.io',
  company: {
    name: companyName,
    nameFull: `${companyName}, LLC`,
    address: {
      street1: '1309 Coffeen Ave Ste 1200',
      street2: '',
      city: 'Sheridan',
      region: 'WY',
      postalCode: '82801'
    },
  },
  phone: {
    main: '8559676338'
  },
  email: {
    support: `support@${domain}`,
    inquiries: `inquiries@${domain}`,
    noreply: `noreply@${domain}`,
    admin: `admin@${domain}`
  },
  socials: {
    facebook: 'https://www.facebook.com/veterantechio',
    twitter: 'https://twitter.com/veterantechio',
    instagram: 'https://www.instagram.com/veterantechio',
    youtube: 'https://www.youtube.com/channel/UCEX1TQqYxpc2yYuZZ3iqr1A',
    linkedin: 'https://www.linkedin.com/company/veterantechio',
    github: 'https://github.com/veterantechio',
    discord: 'https://discord.gg/P7fhJdxuNv',
    twitch: 'https://www.twitch.tv/vetsintech',
  },
  developer: {
    apis: {
      blog: `https://api.${domain}/blog`,
      contact: `https://api.${domain}/contact`,
      subscribers: `https://api.${domain}/subscribers`,
    },
    socialAppIds: {
      facebook: '873875133480930',
      twitter: '',
      wordpress: '',
      medium: ''
    }
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
