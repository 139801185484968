import { Pipe, PipeTransform } from '@angular/core';
import {switchAll} from "rxjs/operators";

@Pipe({
  name: 'phoneNumberFormatter'
})
export class PhoneNumberFormatterPipe implements PipeTransform {
  private validPhoneFormats: string[] = ['xxx-xxx-xxxx', '(xxx) xxx-xxxx']
  transform(value: string, ...args: string[]): string {
    if (!value) return '';
    if (args && args[0] && !this.validPhoneFormats.includes(args[0])) {
      throw new Error(`'${args[0]}' is not a valid phone number format`);
    }
    // remove any non numeric values so we can start from nothing but a string of numbers
    value = value.replace(/[^0-9]/g, '');
    switch (args[0]) {
      case 'xxx-xxx-xxxx':
        return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
      case '(xxx) xxx-xxxx':
      default:
        return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }
  }
}
