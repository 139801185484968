<div class="container">
  <div class="row">
    <div class="col-xl-8 col-lg-7">
      <div class="card card-primary animated fadeInUp animation-delay-7">
        <div class="ms-hero-bg-primary ms-hero-img-mountain">
          <h2 class="text-center no-m pt-4 pb-4 color-white index-1">Contact</h2>
        </div>
        <vt-contact-form></vt-contact-form>
      </div>
    </div>
    <div class="col-xl-4 col-lg-5">
      <div class="card card-primary animated fadeInUp animation-delay-7">
        <div class="card-body">
          <div class="text-center mb-2">
            <img src="https://static.veterantech.io/images/logos/profile.png" height="40">&nbsp;
            <h3 class="no-m ms-site-title"> Veteran<span>Technologies</span></h3>
          </div>
          <address class="no-mb">
            <p><i class="color-danger-light zmdi zmdi-pin mr-1"></i><a href="{{googleMapsLink}}">{{environment.company.address.street1}} <br>
              <span *ngIf="environment.company.address.street2">{{environment.company.address.street2}} <br></span>
              {{environment.company.address.city}}, {{environment.company.address.region}} {{environment.company.address.postalCode}}</a></p>
            <p><i class="color-info-light zmdi zmdi-email mr-1"></i> <a href="mailto:{{environment.email.inquiries}}">{{environment.email.inquiries}}</a></p>
          </address>
        </div>
      </div>
      <div class="card card-primary animated fadeInUp animation-delay-7">
        <div class="card-header">
          <h3 class="card-title"><i class="zmdi zmdi-map"></i>Map</h3>
        </div>
        <iframe width="100%" height="340"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d45300.84899732213!2d-106.953488!3d44.795012!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5335f009b7255555%3A0x7037fbf770c90986!2s1309%20Coffeen%20Ave%20ST%201200%2C%20Sheridan%2C%20WY%2082801!5e0!3m2!1sen!2sus!4v1722913307437!5m2!1sen!2sus"
                style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
    </div>
  </div>
</div> <!-- container -->
