import * as env from './environment.base';
import * as _ from 'lodash';
import { environments } from './environment.base';

const domain: string = `${environments.DEV}.${env.domain}`;
const tempEnv: any = env.environment;
tempEnv.env = environments.DEV;
tempEnv.domain = domain;
tempEnv.baseUrl = `https://${domain}`;
tempEnv.siteInfo = _.assignIn(tempEnv, {
  hostname: `https://${domain}`,
});
tempEnv.developer.apis.contact = `https://api.${domain}/contact`;
tempEnv.developer.apis.subscribers = `https://api.${domain}/subscribers`;
tempEnv.developer.apis.blog = `https://api.${domain}/blog`;

export const environment: any = tempEnv;
