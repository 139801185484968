import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { Error404Component } from "./shared/error404/error404.component";
import { Error500Component } from "./shared/error500/error500.component";
import { HomeComponent } from "./base/home/home.component";
import { AboutComponent } from "./base/about/about.component";
import { ContactUsComponent } from "./base/contact-us/contact-us.component";
import { PrivacyPolicyComponent } from './base/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './base/terms-of-use/terms-of-use.component';

const routes: Routes = [
  { component: Error500Component, path: 'error', pathMatch: 'full' },
  { component: AboutComponent, path: 'about', pathMatch: 'full' },
  { component: ContactUsComponent, path: 'contact', pathMatch: 'full' },
  { component: PrivacyPolicyComponent, path: 'privacy-policy', pathMatch: 'full' },
  { component: TermsOfUseComponent, path: 'terms-of-use', pathMatch: 'full' },
  { component: HomeComponent, path: '', pathMatch: 'full' },
  { component: Error404Component, path: '**' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking', // 'enabledBlocking' is required for Angular Universal to work
    enableTracing: !environment.production
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
