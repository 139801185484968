import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'vt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public environment: any;

  constructor() {
    this.environment = environment;
  }

  ngOnInit(): void {
  }

  public share(socialNetwork: string): void {
    let link: string = '';
    switch (socialNetwork) {
      case 'facebook':
        // tslint:disable-next-line:max-line-length
        link = `https://www.facebook.com/dialog/share?app_id=${environment.developer.socialAppIds.facebook}&display=popup&href=${environment.baseUrl}&quote=Checkout the software, mobile app, DevOps, and website services offered by ${environment.company.name}! Check them out here: https://www.facebook.com/veterantechio`;
        break;
      case 'twitter':
        // tslint:disable-next-line:max-line-length
        link = `https://twitter.com/intent/tweet?text=Check out the software, mobile app, DevOps, and website services offered by @veterantechio! ${environment.baseUrl}`;
        break;
      default:
        return;
    }
    //this.googleAnalyticsService.emitEvent('engagement', 'share', socialNetwork);
    window.open(encodeURI(link), '_blank');
  }
}
