<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-8 col-md-offset-2">
      <div class="card animated fadeInUp animation-delay-7 color-primary withripple">
        <div class="card-body-big color-dark">
          <div class="text-center">
            <h1 class="color-primary">Oops!</h1>
            <h2>404 - Page Not Found</h2>
            <p class="lead lead-sm">You may have taken a wrong turn.<br>Please go back and try your request again.</p>
            <a href="/" class="btn btn-primary btn-raised"><i class="zmdi zmdi-home"></i> Go Home</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> <!-- container -->
