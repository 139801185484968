import { Component, OnChanges, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {environment} from '../../../environments/environment';
import {Helpers} from "../helpers/helper-methods";

@Component({
  selector: 'vt-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public environment: any;
  public currentYear: number;
  public socialFacebook: string;
  public socialTwitter: string;
  public socialYouTube: string;
  public socialInstagram: string;
  public socialLinkedIn: string;
  public socialGitHub: string;
  public googleMapsLink: string;
  public currentUrl: string;

  constructor(private router: Router, private location: Location) {
    this.environment = environment;
    this.currentYear = new Date().getUTCFullYear();
    this.socialFacebook = environment.socials.facebook;
    this.socialTwitter = environment.socials.twitter;
    this.socialInstagram = environment.socials.instagram;
    this.socialLinkedIn = environment.socials.linkedin;
    this.socialYouTube = environment.socials.youtube;
    this.socialGitHub = environment.socials.github;
    this.googleMapsLink = Helpers.getGoogleMapsLink();
    router.events.subscribe((val) => {
      if(location.path() != ''){
        this.currentUrl = `${location.path()}#`;
      } else {
        this.currentUrl = '/';
      }
    });
    this.currentUrl = this.router.url;
  }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
    console.log(this.currentUrl);
  }

  public openSocialProfile(socialNetwork: string) {
    switch (socialNetwork) {
      case 'facebook':
        window.open(this.socialFacebook, '_blank');
        break;
      case 'twitter':
        window.open(this.socialTwitter, '_blank');
        break;
      case 'youtube':
        window.open(this.socialYouTube, '_blank');
        break;
      case 'instagram':
        window.open(this.socialInstagram, '_blank');
        break;
      case 'linkedin':
        window.open(this.socialLinkedIn, '_blank');
        break;
      case 'github':
        window.open(this.socialGitHub, '_blank');
        break;
      default:
        break;
    }
  }
}
