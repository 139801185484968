<div class="message-box" *ngIf="formErrors.email.length > 0 || formErrors.general.length > 0">
<div class="alert alert-danger alert-light message-list" role="alert">
<p *ngFor="let error of formErrors.email">
 <strong>
   <i class="zmdi zmdi-close-circle"></i>
 </strong> {{ error }}</p>
<p *ngFor="let error of formErrors.general">
 <strong>
   <i class="zmdi zmdi-close-circle"></i>
 </strong> {{ error }}</p>
</div>
</div>
<div *ngIf="showSuccessMessage" class="alert alert-success alert-light">
<strong>
<i class="zmdi zmdi-check"></i>
</strong> Thanks for subscribing! You should receive a confirmation email shortly!
</div>
<h6 class="control-label">
  By clicking Submit, I agree to the <a href="/terms-of-use" target="_blank">Terms of Use</a> and
  <a href="/privacy-policy" target="_blank">Privacy Policy</a>
</h6>
<form class="form-horizontal" [formGroup]="subscribeForm" novalidate role="form">
  <div class="form-group label-floating mt-2 mb-1">
    <div class="input-group ms-input-subscribe">
      <label class="control-label" for="email"><i class="zmdi zmdi-email"></i> Email Address</label>
      <input type="email" name="email" id="email" class="form-control" formControlName="email" required maxlength="100">
    </div>
  </div>
  <button class="ms-subscribre-btn" type="submit" [disabled]="!subscribeForm.valid || subscribeFormSubmitted" (click)="submitForm(subscribeForm);">Subscribe</button>
</form>
