<div class="ms-slidebar sb-slidebar sb-left sb-style-overlay" id="ms-slidebar">
  <div class="sb-slidebar-container">
    <header class="ms-slidebar-header">
      <!-- <div class="ms-slidebar-login">
        <a href="javascript:void(0)" class="withripple"><i class="zmdi zmdi-account"></i> Login</a>
        <a href="javascript:void(0)" class="withripple"><i class="zmdi zmdi-account-add"></i> Register</a>
      </div> -->
      <div class="ms-slidebar-title">
        <!-- <form class="search-form">
          <input id="search-box-slidebar" type="text" class="search-input" placeholder="Search..." name="q" />
          <label for="search-box-slidebar"><i class="zmdi zmdi-search"></i></label>
        </form> -->
        <div class="ms-slidebar-t">
          <img src="{{environment.businessMediaHref}}/images/logos/profile.png" height="40">&nbsp;
          <h3>Veteran<span>Technologies</span></h3>
        </div>
      </div>
    </header>
    <ul class="ms-slidebar-menu" id="slidebar-menu">
      <li class="card" role="tab" id="sch1">
        <a role="button" routerLink="/" routerLinkActive="active">
          <i class="zmdi zmdi-home"></i> Home </a>
        <!-- <a class="collapsed" role="button" data-toggle="collapse" href="#sc1" aria-expanded="false" aria-controls="sc1">
          <i class="zmdi zmdi-home"></i> Home </a>
        <ul id="sc1" class="card-collapse collapse" role="tabpanel" aria-labelledby="sch1" data-parent="#slidebar-menu">
          <li><a routerLink="/">Home</a></li>
          <li><a routerLink="/about">About Us</a></li>
          <li><a href="home-landing.html">Home Landing Intro</a></li>
          <li><a href="home-landing3.html">Home Landing Video</a></li>
          <li><a href="home-shop.html">Home Shop 1</a></li>
        </ul> -->
      </li>
      <li class="card" role="tab" id="sch1">
        <a role="button" routerLink="/about" routerLinkActive="active">
          <i class="zmdi zmdi-favorite-outline"></i> About Us </a>
      </li>
      <li class="card" role="tab" id="sch1">
        <a role="button" routerLink="/contact" routerLinkActive="active">
          <i class="zmdi zmdi-email"></i> Contact Us </a>
      </li>
      <!-- <li class="card" role="tab" id="sch2">
        <a class="collapsed" role="button" data-toggle="collapse" href="#sc2" aria-expanded="false" aria-controls="sc2">
          <i class="zmdi zmdi-desktop-mac"></i> Pages </a>
        <ul id="sc2" class="card-collapse collapse" role="tabpanel" aria-labelledby="sch2" data-parent="#slidebar-menu">
          <li><a href="page-about.html">About US</a></li>
          <li><a href="page-team.html">Our Team</a></li>
          <li><a href="page-product.html">Products</a></li>
          <li><a href="page-services.html">Services</a></li>
          <li><a href="page-faq.html">FAQ</a></li>
          <li><a href="page-timeline_left.html">Timeline</a></li>
          <li><a href="page-contact.html">Contact Option</a></li>
          <li><a href="page-login.html">Login</a></li>
          <li><a href="page-pricing.html">Pricing</a></li>
          <li><a href="page-coming.html">Coming Soon</a></li>
        </ul>
      </li>
      <li class="card" role="tab" id="sch4">
        <a class="collapsed" role="button" data-toggle="collapse" href="#sc4" aria-expanded="false" aria-controls="sc4">
          <i class="zmdi zmdi-edit"></i> Blog </a>
        <ul id="sc4" class="card-collapse collapse" role="tabpanel" aria-labelledby="sch4" data-parent="#slidebar-menu">
          <li><a href="blog-sidebar.html">Blog Sidebar 1</a></li>
          <li><a href="blog-sidebar2.html">Blog Sidebar 2</a></li>
          <li><a href="blog-masonry.html">Blog Masonry 1</a></li>
          <li><a href="blog-masonry2.html">Blog Masonry 2</a></li>
          <li><a href="blog-full.html">Blog Full Page 1</a></li>
          <li><a href="blog-full2.html">Blog Full Page 2</a></li>
          <li><a href="blog-post.html">Blog Post 1</a></li>
          <li><a href="blog-post2.html">Blog Post 2</a></li>
        </ul>
      </li>
      <li class="card" role="tab" id="sch5">
        <a class="collapsed" role="button" data-toggle="collapse" href="#sc5" aria-expanded="false" aria-controls="sc5">
          <i class="zmdi zmdi-shopping-basket"></i> E-Commerce </a>
        <ul id="sc5" class="card-collapse collapse" role="tabpanel" aria-labelledby="sch5" data-parent="#slidebar-menu">
          <li><a href="ecommerce-filters.html">E-Commerce Sidebar</a></li>
          <li><a href="ecommerce-filters-full.html">E-Commerce Sidebar Full</a></li>
          <li><a href="ecommerce-filters-full2.html">E-Commerce Topbar Full</a></li>
          <li><a href="ecommerce-item.html">E-Commerce Item</a></li>
          <li><a href="ecommerce-cart.html">E-Commerce Cart</a></li>
        </ul>
      </li>
      <li class="card" role="tab" id="sch6">
        <a class="collapsed" role="button" data-toggle="collapse" href="#sc6" aria-expanded="false" aria-controls="sc6">
          <i class="zmdi zmdi-collection-image-o"></i> Portfolio </a>
        <ul id="sc6" class="card-collapse collapse" role="tabpanel" aria-labelledby="sch6" data-parent="#slidebar-menu">
          <li><a href="portfolio-filters_sidebar.html">Portfolio Sidebar Filters</a></li>
          <li><a href="portfolio-filters_topbar.html">Portfolio Topbar Filters</a></li>
          <li><a href="portfolio-filters_sidebar_fluid.html">Portfolio Sidebar Fluid</a></li>
          <li><a href="portfolio-filters_topbar_fluid.html">Portfolio Topbar Fluid</a></li>
          <li><a href="portfolio-cards.html">Porfolio Cards</a></li>
          <li><a href="portfolio-masonry.html">Porfolio Masonry</a></li>
          <li><a href="portfolio-item.html">Portfolio Item 1</a></li>
          <li><a href="portfolio-item2.html">Portfolio Item 2</a></li>
        </ul>
      </li>
      <li>
        <a class="link" href="component-typography.html"><i class="zmdi zmdi-view-compact"></i> UI Elements</a>
      </li>
      <li>
        <a class="link" href="page-all.html"><i class="zmdi zmdi-link"></i> All Pages</a>
      </li> -->
    </ul>
    <div class="ms-slidebar-social ms-slidebar-block">
      <h4 class="ms-slidebar-block-title">Social Links</h4>
      <div class="ms-slidebar-social">
        <a href="{{environment.socials.facebook}}" target="_blank" class="btn-circle btn-circle-raised btn-facebook"><i class="zmdi zmdi-facebook"></i>
          <div class="ripple-container"></div>
        </a>
        <a href="{{environment.socials.twitter}}" target="_blank" class="btn-circle btn-circle-raised btn-twitter"><i class="zmdi zmdi-twitter"></i>
          <div class="ripple-container"></div>
        </a>
        <a href="{{environment.socials.instagram}}" target="_blank" class="btn-circle btn-circle-raised btn-instagram"><i class="zmdi zmdi-instagram"></i>
          <div class="ripple-container"></div>
        </a>
        </div>
        <div class="ms-slidebar-social">
          <a href="{{environment.socials.linkedin}}" target="_blank" class="btn-circle btn-circle-raised btn-linkedin"><i class="zmdi zmdi-linkedin"></i>
            <div class="ripple-container"></div>
          </a>
          <a href="{{environment.socials.youtube}}" target="_blank" class="btn-circle btn-circle-raised btn-youtube"><i class="zmdi zmdi-youtube"></i>
            <div class="ripple-container"></div>
          </a>

        <a href="{{environment.socials.github}}" target="_blank" class="btn-circle btn-circle-raised btn-github"><i class="zmdi zmdi-github"></i>
          <div class="ripple-container"></div>
        </a>

        <!-- <a href="{{environment.socials.discord}}" target="_blank" class="btn-circle btn-circle-raised btn-discord"><i class="zmdi zmdi-discord"></i>
          <div class="ripple-container"></div>
        </a>
        <a href="{{environment.socials.twitch}}" target="_blank" class="btn-circle btn-circle-raised btn-twitch"><i class="zmdi zmdi-twitch"></i>
          <div class="ripple-container"></div>
        </a> -->
      </div>
    </div>
  </div>
