<div class="ms-hero ms-hero-material">
  <span class="ms-hero-bg"></span>
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-lg-7">
        <div id="carousel-hero" class="carousel slide carousel-fade" data-ride="carousel" data-interval="8000">
          <!-- Wrapper for slides -->
          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active">
              <div class="carousel-caption">
                <div class="ms-hero-material-text-container">
                  <header class="ms-hero-material-title animated slideInLeft animation-delay-5">
                    <h1 class="animated fadeInLeft animation-delay-15 font-smoothing">Modern, <strong>Responsive</strong> Websites!</h1>
                    <h2 class="animated fadeInLeft animation-delay-18 color-white">Responsive <span class="color-danger bold">websites that adjust</span> to various screen sizes</h2>
                  </header>
                  <ul class="ms-hero-material-list">
                    <li class="">
                      <div class="ms-list-icon animated zoomInUp animation-delay-18">
                        <span class="ms-icon ms-icon-circle ms-icon-xlg color-warning shadow-3dp"><i class="zmdi zmdi-desktop-mac"></i></span>
                      </div>
                      <div class="ms-list-text animated fadeInRight animation-delay-19">We deliver customized static and dynamic websites that will make for a much better user experience.</div>
                    </li>
                    <li class="">
                      <div class="ms-list-icon animated zoomInUp animation-delay-20">
                        <span class="ms-icon ms-icon-circle ms-icon-xlg color-success shadow-3dp"><i class="zmdi zmdi-account"></i></span>
                      </div>
                      <div class="ms-list-text animated fadeInRight animation-delay-21">Need a website that manages user accounts? We can deliver a customized dynamic website that allows users to create accounts!</div>
                    </li>
                    <li class="">
                      <div class="ms-list-icon animated zoomInUp animation-delay-22">
                        <span class="ms-icon ms-icon-circle ms-icon-xlg color-danger shadow-3dp"><i class="zmdi zmdi-cloud"></i></span>
                      </div>
                      <div class="ms-list-text animated fadeInRight animation-delay-23">Need a website that is resilient to single points of failure? We have you covered there as well with cloud solutions based on Amazon Web Services.</div>
                    </li>
                  </ul>
                  <!--                  <div class="ms-hero-material-buttons text-right">-->
                  <!--                    <div class="ms-hero-material-buttons text-right">-->
                  <!--                      <a href="javascript:void(0);" class="btn btn-warning btn-raised animated fadeInLeft animation-delay-24 mr-2"><i class="zmdi zmdi-settings"></i> Personalize</a>-->
                  <!--                      <a href="javascript:void(0);" class="btn btn-success btn-raised animated fadeInRight animation-delay-24"><i class="zmdi zmdi-download"></i> Download</a>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div class="clearfix">&nbsp;</div>
                </div> <!-- ms-hero-material-text-container -->
              </div>
            </div>
            <div class="carousel-item">
              <div class="carousel-caption">
                <div class="ms-hero-material-text-container">
                  <header class="ms-hero-material-title animated slideInLeft animation-delay-5">
                    <h1 class="animated fadeInLeft animation-delay-15">Mobile <strong>application</strong> development</h1>
                    <h2 class="animated fadeInLeft animation-delay-18 color-white">Need a mobile app to accompany <span class="color-danger">your website</span>?</h2>
                  </header>
                  <ul class="ms-hero-material-list">
                    <li class="">
                      <div class="ms-list-icon animated zoomInUp animation-delay-18">
                        <span class="ms-icon ms-icon-circle ms-icon-xlg color-black shadow-3dp"><i class="zmdi zmdi-apple"></i></span>
                      </div>
                      <div class="ms-list-text animated fadeInRight animation-delay-19">We offer customized native iOS application development services</div>
                    </li>
                    <li class="">
                      <div class="ms-list-icon animated zoomInUp animation-delay-20">
                        <span class="ms-icon ms-icon-circle ms-icon-xlg color-success shadow-3dp"><i class="zmdi zmdi-android"></i></span>
                      </div>
                      <div class="ms-list-text animated fadeInRight animation-delay-21">We offer customized native Android application development services<div class="clearfix"></div> </div>
                    </li>
                    <li class="">
                      <div class="ms-list-icon animated zoomInUp animation-delay-22">
                        <span class="ms-icon ms-icon-circle ms-icon-xlg color-warning shadow-3dp"><i class="zmdi zmdi-view-web"></i></span>
                      </div>
                      <div class="ms-list-text animated fadeInRight animation-delay-23">Have a website? We can integrate your mobile app's data with your website so they always stay in sync!</div>
                    </li>
                  </ul>
                  <!--                  <div class="ms-hero-material-buttons text-right">-->
                  <!--                    <div class="ms-hero-material-buttons text-right">-->
                  <!--                      <a href="javascript:void(0);" class="btn btn-warning btn-raised animated fadeInLeft animation-delay-24 mr-2"><i class="zmdi zmdi-settings"></i> Personalize</a>-->
                  <!--                      <a href="javascript:void(0);" class="btn btn-success btn-raised animated fadeInRight animation-delay-24"><i class="zmdi zmdi-download"></i> Download</a>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div class="clearfix">&nbsp;</div>
                  <div class="clearfix">&nbsp;</div>
                  <div class="clearfix">&nbsp;</div>
                </div> <!-- ms-hero-material-text-container -->
              </div>
            </div>
            <div class="carousel-item">
              <div class="carousel-caption">
                <div class="ms-hero-material-text-container">
                  <header class="ms-hero-material-title animated slideInLeft animation-delay-5">
                    <h1 class="animated fadeInLeft animation-delay-15">Monthly <strong>Service Plans</strong></h1>
                    <h2 class="animated fadeInLeft animation-delay-18 color-white">Monthly service plans for <span class="color-warning">updates, emergency on-call requests</span>, and more!</h2>
                  </header>
                  <ul class="ms-hero-material-list">
                    <li class="">
                      <div class="ms-list-icon animated zoomInUp animation-delay-18">
                        <span class="ms-icon ms-icon-circle ms-icon-xlg color-info shadow-3dp"><i class="zmdi zmdi-check-circle"></i></span>
                      </div>
                      <div class="ms-list-text animated fadeInRight animation-delay-19">Have peace of mind knowing you have someone to call at any time of day when your website or mobile apps are having issues.</div>
                    </li>
                    <li class="">
                      <div class="ms-list-icon animated zoomInUp animation-delay-20">
                        <span class="ms-icon ms-icon-circle ms-icon-xlg color-danger shadow-3dp"><i class="zmdi zmdi-calendar"></i></span>
                      </div>
                      <div class="ms-list-text animated fadeInRight animation-delay-21">Know you can request updates to your website or mobile app any time and for any reason (within number of hours purchased per month)</div>
                    </li>
                    <li class="">
                      <div class="ms-list-icon animated zoomInUp animation-delay-22">
                        <span class="ms-icon ms-icon-circle ms-icon-xlg color-success shadow-3dp"><i class="zmdi zmdi-thumb-up"></i></span>
                      </div>
                      <div class="ms-list-text animated fadeInRight animation-delay-23">We are here to help you succeed! Please let us know how we can help you and your company out!</div>
                    </li>
                  </ul>
                  <!--                  <div class="ms-hero-material-buttons text-right">-->
                  <!--                    <a href="javascript:void(0);" class="btn btn-warning btn-raised animated fadeInLeft animation-delay-24 mr-2"><i class="zmdi zmdi-settings"></i> Personalize</a>-->
                  <!--                    <a href="javascript:void(0);" class="btn btn-success btn-raised animated fadeInRight animation-delay-24"><i class="zmdi zmdi-download"></i> Download</a>-->
                  <!--                  </div>-->
                  <div class="clearfix">&nbsp;</div>
                </div> <!-- ms-hero-material-text-container -->
              </div>
            </div>
            <div class="carousel-controls">
              <!-- Controls -->
              <a class="left carousel-control animated zoomIn animation-delay-30" href="#carousel-hero" role="button" data-slide="prev">
                <i class="zmdi zmdi-chevron-left"></i>
                <span class="sr-only">Previous</span>
              </a>
              <a class="right carousel-control animated zoomIn animation-delay-30" href="#carousel-hero" role="button" data-slide="next">
                <i class="zmdi zmdi-chevron-right"></i>
                <span class="sr-only">Next</span>
              </a>
              <!-- Indicators -->
              <ol class="carousel-indicators">
                <li data-target="#carousel-hero" data-slide-to="0" class="animated fadeInUpBig animation-delay-27 active"></li>
                <li data-target="#carousel-hero" data-slide-to="1" class="animated fadeInUpBig animation-delay-28"></li>
                <li data-target="#carousel-hero" data-slide-to="2" class="animated fadeInUpBig animation-delay-29"></li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-5">
        <div class="ms-hero-img animated zoomInUp animation-delay-30">
          <img src="{{environment.baseAssetsHref}}/images/demo/mock.png" alt="" class="img-fluid">
          <!--          <div id="carousel-hero-img" class="carousel carousel-fade slide" data-ride="carousel" data-interval="3000">-->
          <!--            &lt;!&ndash; Indicators &ndash;&gt;-->
          <!--            <ol class="carousel-indicators carousel-indicators-hero-img">-->
          <!--              <li data-target="#carousel-hero-img" data-slide-to="0" class="active"></li>-->
          <!--              <li data-target="#carousel-hero-img" data-slide-to="1"></li>-->
          <!--              <li data-target="#carousel-hero-img" data-slide-to="2"></li>-->
          <!--            </ol>-->
          <!--            &lt;!&ndash; Wrapper for slides &ndash;&gt;-->
          <!--            <div class="carousel-inner" role="listbox">-->
          <!--              <div class="ms-hero-img-slider carousel-item active">-->
          <!--                <img src="{{environment.baseAssetsHref}}/images/demo/mock.png" alt=""
          class="img-fluid">-->
          <!--              </div>-->
          <!--              <div class="ms-hero-img-slider carousel-item">-->
          <!--                <img src="{{environment.baseAssetsHref}}/images/demo/hero3.png" alt="" class="img-fluid">-->
          <!--              </div>-->
          <!--              <div class="ms-hero-img-slider carousel-item">-->
          <!--                <img src="{{environment.baseAssetsHref}}/images/demo/hero2.png" alt="" class="img-fluid">-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div> <!-- container -->
</div> <!-- ms-hero ms-hero-black -->
<div class="container mt-4">
  <h2 class="text-center color-primary mb-2 wow fadeInDown animation-delay-4">How We Can Help You Succeed!</h2>
  <p class="lead text-center aco wow fadeInDown animation-delay-5 mw-800 center-block mb-4"> Other companies may offer more services than we do. However, we make sure that you are <span class="color-danger bold">always</span> satisfied with the results you get from us!</p>
  <div class="row">
    <div class="ms-feature col-xl-3 col-lg-6 col-md-6 card wow flipInX animation-delay-4">
      <div class="text-center card-body">
        <span class="ms-icon ms-icon-circle ms-icon-xxlg color-info"><i class="zmdi zmdi-cloud"></i></span>
        <h4 class="color-info">Cloud Solutions With AWS</h4>
        <p class="">We offer multiple services that we can deploy to Amazon Web Services to enhance the security and resiliency of your website and/or mobile apps</p>
      </div>
    </div>
    <div class="ms-feature col-xl-3 col-lg-6 col-md-6 card wow flipInX animation-delay-8">
      <div class="text-center card-body">
        <span class="ms-icon ms-icon-circle ms-icon-xxlg color-warning"><i class="zmdi zmdi-desktop-mac"></i></span>
        <h4 class="color-warning">Customized Websites Specific For Your Needs!</h4>
        <p class="">
          Whether its ecommerce, a static informational website, highly dynamic single page application (SPA), or something else,
          we have you covered!
        </p>
      </div>
    </div>
    <div class="ms-feature col-xl-3 col-lg-6 col-md-6 card wow flipInX animation-delay-10">
      <div class="text-center card-body">
        <span class="ms-icon ms-icon-circle ms-icon-xxlg color-success"><i class="zmdi zmdi-smartphone"></i></span>
        <h4 class="color-success">iOS and Android Mobile Apps</h4>
        <p class="">
          We develop a customized native mobile application and will upload it to the app stores for you if desired!
        </p>
      </div>
    </div>
    <div class="ms-feature col-xl-3 col-lg-6 col-md-6 card wow flipInX animation-delay-6">
      <div class="text-center card-body">
        <span class="ms-icon ms-icon-circle ms-icon-xxlg  color-danger"><i class="zmdi zmdi-smartphone-ring"></i></span>
        <h4 class="color-danger">Option for 24/7 on-call services</h4>
        <p class="">
          We offer the ability to purchase a monthly service option that allows for 24/7 on-call support for emergencies when
          your mobile app or website is not functioning correctly
        </p>
      </div>
    </div>
    <div class="col-12 text-center">
      <a routerLink="/contact" class="btn btn-success btn-raised"><i class="zmdi zmdi-email"></i> Contact Us Now!</a>
    </div>
  </div>
</div>
<!-- container -->
<div class="wrap wrap-mountain mt-6">
  <div class="container">
    <h2 class="text-center text-light mb-6 wow fadeInDown animation-delay-5">Set Your Business Up <strong>For Success!</strong></h2>
    <div class="row">
      <div class="col-lg-6 order-lg-2 mb-4  center-block">
        <img src="{{environment.baseAssetsHref}}/images/demo/mock.png" alt=""
             class="img-fluid center-block wow zoomIn animation-delay-12 ">
      </div>
      <div class="col-lg-6 order-lg-1 pr-6">
        <p class="wow fadeInLeft animation-delay-6">
          Contact us now to get your website and mobile app(s) customized and working flawlessly! With the added benefit of being able to
          contact someone anytime for updates or when your website or mobile app go down (requires monthly service plan).
        </p>
        <div class="text-center">
          <a routerLink="/contact" class="btn btn-warning btn-raised mr-1 wow flipInX animation-delay-14"><i class="zmdi zmdi-email"></i> Contact Us Now! </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container mt-6">
  <div class="text-center mb-4">
    <h2 class="uppercase color-primary">See our monthly service plans</h2>
  </div>
  <div class="row no-gutters">
    <div class="col-lg-4">
      <div class="price-table price-table-info wow zoomInUp animation-delay-2">
        <header class="price-table-header">
          <span class="price-table-category">Starter</span>
          <h3><sup>$</sup>200 <sub>/mo.</sub></h3>
        </header>
        <div class="price-table-body">
          <ul class="price-table-list">
            <li><i class="zmdi zmdi-code"></i> 1 hour of support per month</li>
            <li><i class="zmdi zmdi-globe"></i> Website or mobile apps updates</li>
            <li><i class="zmdi zmdi-phone-ring"></i> Emergency on-call requests for outages</li>
          </ul>
          <div class="text-center">
            <a href="javascript:void(0)" class="btn btn-info btn-raised"><i class="zmdi zmdi-cloud-download"></i> Get Now</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="price-table price-table-success prominent wow zoomInDown animation-delay-2">
        <header class="price-table-header">
          <span class="price-table-category">Standard</span>
          <h3><sup>$</sup>500 <sub>/mo.</sub></h3>
        </header>
        <div class="price-table-body">
          <ul class="price-table-list">
            <li><i class="zmdi zmdi-code"></i> 5 hours of support per month</li>
            <li><i class="zmdi zmdi-globe"></i> Website or mobile apps updates</li>
            <li><i class="zmdi zmdi-phone-ring"></i> Emergency on-call requests for outages</li>
          </ul>
          <div class="text-center">
            <a href="javascript:void(0)" class="btn btn-success btn-raised"><i class="zmdi zmdi-cloud-download"></i> Get Now</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="price-table price-table-warning wow zoomInUp animation-delay-2">
        <header class="price-table-header">
          <span class="price-table-category">Advanced</span>
          <h3><sup>$</sup>1000 <sub>/mo.</sub></h3>
        </header>
        <div class="price-table-body">
          <ul class="price-table-list">
            <li><i class="zmdi zmdi-code"></i> 12 hours of support per month</li>
            <li><i class="zmdi zmdi-globe"></i> Website or mobile apps updates</li>
            <li><i class="zmdi zmdi-phone-ring"></i> Emergency on-call requests for outages</li>
          </ul>
          <div class="text-center">
            <a href="javascript:void(0)" class="btn btn-warning btn-raised"><i class="zmdi zmdi-cloud-download"></i> Get Now</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--container -->
<!--<div class="wrap wrap-danger mt-6">-->
<!--  <h2 class="text-center no-m">What our customers say</h2>-->
<!--  <div id="carousel-example-generic" class="carousel carousel-cards carousel-fade slide" data-ride="carousel" data-interval="7000">-->
<!--    &lt;!&ndash; Indicators &ndash;&gt;-->
<!--    <ol class="carousel-indicators">-->
<!--      <li data-target="#carousel-example-generic" data-slide-to="0" class="active"></li>-->
<!--      <li data-target="#carousel-example-generic" data-slide-to="1"></li>-->
<!--      <li data-target="#carousel-example-generic" data-slide-to="2"></li>-->
<!--    </ol>-->
<!--    &lt;!&ndash; Wrapper for slides &ndash;&gt;-->
<!--    <div class="carousel-inner" role="listbox">-->
<!--      <div class="carousel-item active">-->
<!--        <div class="carousel-caption">-->
<!--          <div class="container">-->
<!--            <div class="row">-->
<!--              <div class="col-lg-4">-->
<!--                <div class="card animated flipInX animation-delay-2 mb-4">-->
<!--                  <blockquote class="blockquote blockquote-avatar withripple">-->
<!--                    <img src="{{environment.baseAssetsHref}}/images/demo/avatar.png" alt="" class="avatar d-none d-sm-block">-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante ultricies nisi vel augue quam semper libero.</p>-->
<!--                    <footer>Brian Krzanich, Intel CEO.</footer>-->
<!--                  </blockquote>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-lg-4">-->
<!--                <div class="card animated flipInX animation-delay-3 mb-4">-->
<!--                  <blockquote class="blockquote blockquote-avatar withripple">-->
<!--                    <img src="{{environment.baseAssetsHref}}/images/demo/avatar.png" alt="" class="avatar d-none d-sm-block">-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante ultricies nisi vel augue quam semper libero.</p>-->
<!--                    <footer>Brian Krzanich, Intel CEO.</footer>-->
<!--                  </blockquote>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-lg-4">-->
<!--                <div class="card animated flipInX animation-delay-4 mb-4">-->
<!--                  <blockquote class="blockquote blockquote-avatar withripple">-->
<!--                    <img src="{{environment.baseAssetsHref}}/images/demo/avatar.png" alt="" class="avatar d-none d-sm-block">-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante ultricies nisi vel augue quam semper libero.</p>-->
<!--                    <footer>Brian Krzanich, Intel CEO.</footer>-->
<!--                  </blockquote>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="carousel-item">-->
<!--        <div class="carousel-caption">-->
<!--          <div class="container">-->
<!--            <div class="row">-->
<!--              <div class="col-lg-4">-->
<!--                <div class="card animated flipInX animation-delay-2 mb-4">-->
<!--                  <blockquote class="blockquote blockquote-avatar withripple">-->
<!--                    <img src="{{environment.baseAssetsHref}}/images/demo/avatar.png" alt="" class="avatar d-none d-sm-block">-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante ultricies nisi vel augue quam semper libero.</p>-->
<!--                    <footer>Brian Krzanich, Intel CEO.</footer>-->
<!--                  </blockquote>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-lg-4">-->
<!--                <div class="card animated flipInX animation-delay-3 mb-4">-->
<!--                  <blockquote class="blockquote blockquote-avatar withripple">-->
<!--                    <img src="{{environment.baseAssetsHref}}/images/demo/avatar.png" alt="" class="avatar d-none d-sm-block">-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante ultricies nisi vel augue quam semper libero.</p>-->
<!--                    <footer>Brian Krzanich, Intel CEO.</footer>-->
<!--                  </blockquote>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-lg-4">-->
<!--                <div class="card animated flipInX animation-delay-4 mb-4">-->
<!--                  <blockquote class="blockquote blockquote-avatar withripple">-->
<!--                    <img src="{{environment.baseAssetsHref}}/images/demo/avatar.png" alt="" class="avatar d-none d-sm-block">-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante ultricies nisi vel augue quam semper libero.</p>-->
<!--                    <footer>Brian Krzanich, Intel CEO.</footer>-->
<!--                  </blockquote>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="carousel-item">-->
<!--        <div class="carousel-caption">-->
<!--          <div class="container">-->
<!--            <div class="row">-->
<!--              <div class="col-lg-4">-->
<!--                <div class="card animated flipInX animation-delay-2 mb-4">-->
<!--                  <blockquote class="blockquote blockquote-avatar withripple">-->
<!--                    <img src="{{environment.baseAssetsHref}}/images/demo/avatar.png" alt="" class="avatar d-none d-sm-block">-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante ultricies nisi vel augue quam semper libero.</p>-->
<!--                    <footer>Brian Krzanich, Intel CEO.</footer>-->
<!--                  </blockquote>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-lg-4">-->
<!--                <div class="card animated flipInX animation-delay-3 mb-4">-->
<!--                  <blockquote class="blockquote blockquote-avatar withripple">-->
<!--                    <img src="{{environment.baseAssetsHref}}/images/demo/avatar.png" alt="" class="avatar d-none d-sm-block">-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante ultricies nisi vel augue quam semper libero.</p>-->
<!--                    <footer>Brian Krzanich, Intel CEO.</footer>-->
<!--                  </blockquote>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-lg-4">-->
<!--                <div class="card animated flipInX animation-delay-4 mb-4">-->
<!--                  <blockquote class="blockquote blockquote-avatar withripple">-->
<!--                    <img src="{{environment.baseAssetsHref}}/images/demo/avatar.png" alt="" class="avatar d-none d-sm-block">-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante ultricies nisi vel augue quam semper libero.</p>-->
<!--                    <footer>Brian Krzanich, Intel CEO.</footer>-->
<!--                  </blockquote>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash; Controls &ndash;&gt;-->
<!--    <a class="left carousel-control-prev btn btn-white btn-raised" href="#carousel-example-generic" role="button" data-slide="next">-->
<!--      <i class="zmdi zmdi-arrow-left"></i>-->
<!--    </a>-->
<!--    <a class="right carousel-control-next btn btn-white btn-raised" href="#carousel-example-generic" role="button" data-slide="next">-->
<!--      <i class="zmdi zmdi-arrow-right"></i>-->
<!--    </a>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="container mt-6">-->
<!--  <h2 class="text-center color-primary mb-4">Our Latest Works</h2>-->
<!--  <div class="owl-dots"></div>-->
<!--  <div class="owl-carousel owl-theme">-->
<!--    <div class="card animation-delay-6">-->
<!--      <div class="withripple zoom-img">-->
<!--        <a href="javascript:void()"><img src="{{environment.baseAssetsHref}}/images/demo/port4.jpg" alt="..." class="img-fluid"></a>-->
<!--      </div>-->
<!--      <div class="card-body">-->
<!--        <h3 class="color-primary">Thumbnail label</h3>-->
<!--        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem, repellat, vitae porro ex expedita cumque nulla.</p>-->
<!--        <p class="text-right">-->
<!--          <a href="javascript:void()" class="btn btn-primary btn-raised text-right" role="button"><i class="zmdi zmdi-collection-image-o"></i> View More</a>-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="card card-dark-inverse animation-delay-8">-->
<!--      <div class="withripple zoom-img">-->
<!--        <a href="javascript:void()"><img src="{{environment.baseAssetsHref}}/images/demo/port24.jpg" alt="..." class="img-fluid"></a>-->
<!--      </div>-->
<!--      <div class="card-body">-->
<!--        <h3 class="">Thumbnail label</h3>-->
<!--        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem, repellat, vitae porro ex expedita cumque nulla.</p>-->
<!--        <p class="text-right">-->
<!--          <a href="javascript:void()" class="btn btn-info btn-raised text-right" role="button"><i class="zmdi zmdi-collection-image-o"></i> View More</a>-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="card animation-delay-10">-->
<!--      <div class="withripple zoom-img">-->
<!--        <a href="javascript:void()"><img src="{{environment.baseAssetsHref}}/images/demo/port7.jpg" alt="..." class="img-fluid"></a>-->
<!--      </div>-->
<!--      <div class="card-body">-->
<!--        <h3 class="color-primary">Thumbnail label</h3>-->
<!--        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem, repellat, vitae porro ex expedita cumque nulla.</p>-->
<!--        <p class="text-right">-->
<!--          <a href="javascript:void()" class="btn btn-primary btn-raised text-right" role="button"><i class="zmdi zmdi-collection-image-o"></i> View More</a>-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="card animation-delay-6">-->
<!--      <div class="withripple zoom-img">-->
<!--        <a href="javascript:void()"><img src="{{environment.baseAssetsHref}}/images/demo/port8.jpg" alt="..." class="img-fluid"></a>-->
<!--      </div>-->
<!--      <div class="card-body">-->
<!--        <h3 class="color-primary">Thumbnail label</h3>-->
<!--        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem, repellat, vitae porro ex expedita cumque nulla.</p>-->
<!--        <p class="text-right">-->
<!--          <a href="javascript:void()" class="btn btn-primary btn-raised text-right" role="button"><i class="zmdi zmdi-collection-image-o"></i> View More</a>-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="card card-dark-inverse animation-delay-8">-->
<!--      <div class="withripple zoom-img">-->
<!--        <a href="javascript:void()"><img src="{{environment.baseAssetsHref}}/images/demo/port9.jpg" alt="..." class="img-fluid"></a>-->
<!--      </div>-->
<!--      <div class="card-body">-->
<!--        <h3 class="">Thumbnail label</h3>-->
<!--        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem, repellat, vitae porro ex expedita cumque nulla.</p>-->
<!--        <p class="text-right">-->
<!--          <a href="javascript:void()" class="btn btn-info btn-raised text-right" role="button"><i class="zmdi zmdi-collection-image-o"></i> View More</a>-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="card animation-delay-10">-->
<!--      <div class="withripple zoom-img">-->
<!--        <a href="javascript:void()"><img src="{{environment.baseAssetsHref}}/images/demo/port5.jpg" alt="..." class="img-fluid"></a>-->
<!--      </div>-->
<!--      <div class="card-body">-->
<!--        <h3 class="color-primary">Thumbnail label</h3>-->
<!--        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem, repellat, vitae porro ex expedita cumque nulla.</p>-->
<!--        <p class="text-right">-->
<!--          <a href="javascript:void()" class="btn btn-primary btn-raised text-right" role="button"><i class="zmdi zmdi-collection-image-o"></i> View More</a>-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="card animation-delay-6">-->
<!--      <div class="withripple zoom-img">-->
<!--        <a href="javascript:void()"><img src="{{environment.baseAssetsHref}}/images/demo/port11.jpg" alt="..." class="img-fluid"></a>-->
<!--      </div>-->
<!--      <div class="card-body">-->
<!--        <h3 class="color-primary">Thumbnail label</h3>-->
<!--        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem, repellat, vitae porro ex expedita cumque nulla.</p>-->
<!--        <p class="text-right">-->
<!--          <a href="javascript:void()" class="btn btn-primary btn-raised text-right" role="button"><i class="zmdi zmdi-collection-image-o"></i> View More</a>-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="card card-dark-inverse animation-delay-8">-->
<!--      <div class="withripple zoom-img">-->
<!--        <a href="javascript:void()"><img src="{{environment.baseAssetsHref}}/images/demo/port3.jpg" alt="..." class="img-fluid"></a>-->
<!--      </div>-->
<!--      <div class="card-body">-->
<!--        <h3 class="">Thumbnail label</h3>-->
<!--        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem, repellat, vitae porro ex expedita cumque nulla.</p>-->
<!--        <p class="text-right">-->
<!--          <a href="javascript:void()" class="btn btn-info btn-raised text-right" role="button"><i class="zmdi zmdi-collection-image-o"></i> View More</a>-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="card animation-delay-10">-->
<!--      <div class="withripple zoom-img">-->
<!--        <a href="javascript:void()"><img src="{{environment.baseAssetsHref}}/images/demo/port14.jpg" alt="..." class="img-fluid"></a>-->
<!--      </div>-->
<!--      <div class="card-body">-->
<!--        <h3 class="color-primary">Thumbnail label</h3>-->
<!--        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem, repellat, vitae porro ex expedita cumque nulla.</p>-->
<!--        <p class="text-right">-->
<!--          <a href="javascript:void()" class="btn btn-primary btn-raised text-right" role="button"><i class="zmdi zmdi-collection-image-o"></i> View More</a>-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
