import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { AccountModalComponent } from './account-modal/account-modal.component';
import { FooterComponent } from './footer/footer.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { PhoneNumberFormatterPipe } from './pipes/phone-number-formatter.pipe';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';
import {RouterModule} from "@angular/router";
import { ContactFormComponent } from './forms/contact-form/contact-form.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { SubscribeFormComponent } from './forms/subscribe-form/subscribe-form.component';
import {
  RecaptchaFormsModule,
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY
} from 'ng-recaptcha';

@NgModule({
  declarations: [
      HeaderComponent,
      NavBarComponent,
      AccountModalComponent,
      FooterComponent,
      SideMenuComponent,
      PhoneNumberFormatterPipe,
      Error404Component,
      Error500Component,
      ContactFormComponent,
      SubscribeFormComponent
  ],
  exports: [
    HeaderComponent,
    NavBarComponent,
    AccountModalComponent,
    FooterComponent,
    SideMenuComponent,
    PhoneNumberFormatterPipe,
    Error404Component,
    Error500Component,
    ContactFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RecaptchaFormsModule
  ],
  providers: [
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: { siteKey: '6LdmbEAeAAAAAH7cfmnmnYEhhncHP-XxdvSqgUyV' } as RecaptchaSettings,
    // },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LeMlUAeAAAAAKjoeeEdAMW777lYSHdFO7GEw0rA'
    },
  ],
})
export class SharedModule { }
