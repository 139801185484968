import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import {RouterModule} from "@angular/router";
import {AppRoutingModule} from "../app-routing.module";
import { ContactUsComponent } from './contact-us/contact-us.component';
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';

@NgModule({
  declarations: [
    HomeComponent,
    AboutComponent,
    ContactUsComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    HomeComponent,
    AboutComponent,
    ContactUsComponent,
    PrivacyPolicyComponent,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class BaseModule { }
