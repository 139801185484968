import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {Helpers} from "../../shared/helpers/helper-methods";

@Component({
  selector: 'vt-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  public environment: any;
  public googleMapsLink: string;

  constructor() {
    this.environment = environment;
    this.googleMapsLink = Helpers.getGoogleMapsLink();
  }

  ngOnInit(): void {
  }

}
