<div class="card-body">
  <div class="message-box"
       *ngIf="formErrors.name.length > 0 || formErrors.email.length > 0 || formErrors.subject.length > 0 || formErrors.message.length > 0 || formErrors.general.length > 0">
    <div class="alert alert-danger alert-light message-list" role="alert">
      <p *ngFor="let error of formErrors.name">
        <strong>
          <i class="zmdi zmdi-close-circle"></i>
        </strong> {{ error }}</p>
      <p *ngFor="let error of formErrors.email">
        <strong>
          <i class="zmdi zmdi-close-circle"></i>
        </strong> {{ error }}</p>
      <p *ngFor="let error of formErrors.subject">
        <strong>
          <i class="zmdi zmdi-close-circle"></i>
        </strong> {{ error }}</p>
      <p *ngFor="let error of formErrors.message">
        <strong>
          <i class="zmdi zmdi-close-circle"></i>
        </strong> {{ error }}</p>
      <p *ngFor="let error of formErrors.general">
        <strong>
          <i class="zmdi zmdi-close-circle"></i>
        </strong> {{ error }}</p>
    </div>
  </div>
  <div *ngIf="showSuccessMessage" class="alert alert-success alert-light">
    <strong>
      <i class="zmdi zmdi-check"></i>
    </strong> Thanks for contacting us! We will review your input as soon as we can!
  </div>
  <form class="form-horizontal" [formGroup]="contactForm" novalidate role="form" id="contactForm">
    <fieldset class="container">
      <div class="form-group row">
        <label for="inputName" autocomplete="false" class="col-lg-2 control-label">Name</label>
        <div class="col-lg-9">
          <input type="text" class="form-control" formControlName="name" id="inputName" placeholder="Name"
                 maxlength="50" required>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail" autocomplete="false" class="col-lg-2 control-label">Email</label>
        <div class="col-lg-9">
          <input type="email" class="form-control" id="inputEmail" placeholder="Email" formControlName="email"
                 maxlength="100" required>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputSubject" autocomplete="false" class="col-lg-2 control-label">Subject</label>
        <div class="col-lg-9">
          <input type="text" class="form-control" id="inputSubject" placeholder="Subject" formControlName="subject"
                 maxlength="80" required>
        </div>
      </div>
      <div class="form-group row">
        <label for="textArea" class="col-lg-2 control-label">Message</label>
        <div class="col-lg-9">
          <textarea class="form-control" rows="3" id="textArea" placeholder="Your message..." formControlName="message"
                    maxlength="500" required></textarea>
        </div>
      </div>
      <div class="formgroup row" style="margin-top: 0;">
        <div class="col-lg-10 offset-lg-2 col-md-10 offset-md-2 col-sm-10 offset-sm-2">
            <input type="checkbox" name="acceptTosPrivacy" id="acceptTosPrivacy" formControlName="acceptTosPrivacy"/>&nbsp;
          <label for="acceptTosPrivacy" class="control-label">
           By clicking Submit, I agree to the <a
              href="/terms-of-use" target="_blank">Terms of Use</a> and
              <a href="/privacy-policy" target="_blank">Privacy Policy</a></label>
          <br />
            <input type="checkbox" name="subscribe" id="subscribe" formControlName="subscribe" [(ngModel)]="this.subscribe" (change)="!this.subscribe"/>&nbsp;
            <label for="subscribe" class="control-label">I wish to sign up for your newsletter and get
              special offers and news</label>
        </div>
      </div>
      <div class="form-group row justify-content-end float-right">
        <div class="col-lg-12">
          <button type="button" class="btn btn-danger" (click)="clearForm();">Cancel</button>
          <button type="submit" class="btn btn-raised btn-primary" id="contactFormSubmitButton"
                  [disabled]="!contactForm.valid || contactFormSubmitted" (click)="submitForm(contactForm);">Submit
          </button>
        </div>
      </div>
    </fieldset>
  </form>
</div>
