<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-8 col-md-offset-2">
      <div class="card animated fadeInUp animation-delay-7 color-primary withripple">
        <div class="card-body-big color-dark">
          <div class="text-center">
            <h1 class="color-primary">Oh snap! That wasn't supposed to happen.. 😳</h1>
            <h2>We hit a snag..</h2>
            <p class="lead lead-sm">Something has gone wrong we are trying to fix it.<br>Our team has been notified of this issue.</p>
            <a href="/" class="btn btn-primary btn-raised"><i class="zmdi zmdi-home"></i> Go Home</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> <!-- container -->
