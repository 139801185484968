<aside class="ms-footbar">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 ms-footer-col">
        <div class="ms-footbar-block">
          <h3 class="ms-footbar-title">Sitemap</h3>
          <ul class="list-unstyled ms-icon-list three_cols">
            <li><a routerLink="/" routerLinkActive="active"><i class="zmdi zmdi-home"></i> Home</a></li>
            <li><a routerLink="/about" routerLinkActive="active"><i class="zmdi zmdi-favorite-outline"></i> About Us</a></li>
<!--            <li><a routerLink="/blog" routerLinkActive="active"><i class="zmdi zmdi-edit"></i> Blog</a></li>
            <li><a href="page-blog.html"><i class="zmdi zmdi-image-o"></i> Portfolio</a></li>
            <li><a href="portfolio-filters_sidebar.html"><i class="zmdi zmdi-case"></i> Works</a></li>
            <li><a href="page-timeline_left2.html"><i class="zmdi zmdi-time"></i> Timeline</a></li>
            <li><a href="page-pricing.html"><i class="zmdi zmdi-money"></i> Pricing</a></li>

            <li><a href="page-team2.html"><i class="zmdi zmdi-accounts"></i> Our Team</a></li>
            <li><a href="page-login2.html"><i class="zmdi zmdi-lock"></i> Login</a></li>-->
<!--            <li><a routerLink="/services" routerLinkActive="active"><i class="zmdi zmdi-case"></i> Services</a></li>-->
           <li><a routerLink="/privacy-policy" routerLinkActive="active"><i class="zmdi zmdi-lock"></i> Privacy</a></li>
           <li><a routerLink="/terms-of-use" routerLinkActive="active"><i class="zmdi zmdi-lock"></i> Terms of Use</a></li>
            <li><a routerLink="/contact" routerLinkActive="active"><i class="zmdi zmdi-email"></i> Contact</a></li>
          </ul>
        </div>
        <div class="ms-footbar-block">
          <h3 class="ms-footbar-title">Subscribe</h3>
          <p class="">Subscribe to our newsletter for latest news as well as promotions for services!</p>
          <vt-subscribe-form></vt-subscribe-form>
        </div>
      </div>
      <div class="col-lg-5 col-md-7 ms-footer-col ms-footer-alt-color">
        <div class="ms-footbar-block">
          <h3 class="ms-footbar-title text-center mb-2">Latest Articles</h3>
          <div class="ms-footer-media">
            <div class="media">
              <div class="media-left media-middle">
                <a href="javascript:void(0)">
                  <img class="media-object media-object-circle" src="https://static.veterantech.io/images/demo/p75.jpg"
                       alt="...">
                </a>
              </div>
              <div class="media-body">
                <h4 class="media-heading"><a href="https://www.devopsauthority.tech/2021/09/28/getting-started-with-github-actions/">Getting Started With GitHub Actions</a></h4>
                <div class="media-footer">
                  <span><i class="zmdi zmdi-time color-info-light"></i> September 28, 2021</span>
                  <span><i class="zmdi zmdi-folder-outline color-warning-light"></i>
                    <a href="https://www.devopsauthority.tech/tag/ci-cd/">ci-cd</a>
                  </span>
                  <span><i class="zmdi zmdi-folder-outline color-warning-light"></i>
                    <a href="https://www.devopsauthority.tech/tag/devops/">devops</a>
                  </span>
                  <span><i class="zmdi zmdi-folder-outline color-warning-light"></i>
                    <a href="https://www.devopsauthority.tech/tag/github/">github</a>
                  </span>
                  <span><i class="zmdi zmdi-folder-outline color-warning-light"></i>
                    <a href="https://www.devopsauthority.tech/tag/github-actions/">github-actions</a>
                  </span>
                  <span><i class="zmdi zmdi-folder-outline color-warning-light"></i>
                    <a href="https://www.devopsauthority.tech/tag/software-development/">software-development</a>
                  </span>
                </div>
              </div>
            </div>
            <div class="media">
              <div class="media-left media-middle">
                <a href="javascript:void(0)">
                  <img class="media-object media-object-circle" src="https://static.veterantech.io/images/demo/p75.jpg"
                       alt="...">
                </a>
              </div>
              <div class="media-body">
                <h4 class="media-heading"><a href="https://www.devopsauthority.tech/2021/05/17/aws-lambda-monitoring-sentry/">AWS Lambda Monitoring with Sentry</a></h4>
                <div class="media-footer">
                  <span><i class="zmdi zmdi-time color-info-light"></i> May 17, 2021</span>
                  <span><i class="zmdi zmdi-folder-outline color-warning-light"></i>
                    <a href="https://www.devopsauthority.tech/tag/aws/">aws</a>
                  </span>
                  <span><i class="zmdi zmdi-folder-outline color-warning-light"></i>
                    <a href="https://www.devopsauthority.tech/tag/lambda/">lambda</a>
                  </span>
                  <span><i class="zmdi zmdi-folder-outline color-warning-light"></i>
                    <a href="https://www.devopsauthority.tech/tag/monitoring/">monitoring</a>
                  </span>
                  <span><i class="zmdi zmdi-folder-outline color-warning-light"></i>
                    <a href="https://www.devopsauthority.tech/tag/sentry/">sentry</a>
                  </span>
                </div>
              </div>
            </div>
            <div class="media">
              <div class="media-left media-middle">
                <a href="javascript:void(0)">
                  <img class="media-object media-object-circle" src="https://static.veterantech.io/images/demo/p75.jpg"
                       alt="...">
                </a>
              </div>
              <div class="media-body">
                <h4 class="media-heading"><a href="https://www.devopsauthority.tech/2021/03/22/how-to-become-a-devops-engineer-3-focus-areas/">How To Become a DevOps Engineer - 3 Focus Areas</a></h4>
                <div class="media-footer">
                  <span><i class="zmdi zmdi-time color-info-light"></i> March 22, 2021</span>
                  <span><i class="zmdi zmdi-folder-outline color-warning-light"></i>
                    <a href="https://www.devopsauthority.tech/tag/ci-cd/">ci-cd</a>
                  </span>
                  <span><i class="zmdi zmdi-folder-outline color-warning-light"></i>
                    <a href="https://www.devopsauthority.tech/tag/continuous-delivery/">continuous-delivery</a>
                  </span>
                  <span><i class="zmdi zmdi-folder-outline color-warning-light"></i>
                    <a href="https://www.devopsauthority.tech/tag/continuous-integration/">continuous-integration</a>
                  </span>
                  <span><i class="zmdi zmdi-folder-outline color-warning-light"></i>
                    <a href="https://www.devopsauthority.tech/tag/devops/">devops</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-5 ms-footer-col ms-footer-text-right">
        <div class="ms-footbar-block">
          <div class="ms-footbar-title">
            <img src="{{environment.businessMediaHref}}/images/logos/profile.png" height="40">&nbsp;
            <h3 class="no-m ms-site-title">Veteran<span>Technologies</span></h3>
          </div>
          <address class="no-mb">
            <p><i class="color-danger-light zmdi zmdi-pin mr-1"></i><a href="{{googleMapsLink}}" target="_blank">
              {{environment.company.address.street1}}
              <span *ngIf="environment.company.address.street2">
                <br />
                {{environment.company.address.street2}}
              </span>
              <br />
              {{environment.company.address.city}}, {{environment.company.address.region}} {{environment.company.address.postalCode}}
            </a>
            </p>
            <p><i class="color-info-light zmdi zmdi-email mr-1"></i> <a href="mailto:{{environment.email.inquiries}}">{{environment.email.inquiries}}</a></p>
<!--            <p><i class="color-success-light fa fa-fax mr-1"></i>+34 123 456 7890 </p>-->
          </address>
        </div>
        <div class="ms-footbar-block">
          <h3 class="ms-footbar-title">Social Media</h3>
          <div class="ms-footbar-social">
            <a (click)="openSocialProfile('facebook')" class="btn-circle btn-facebook"><i class="zmdi zmdi-facebook"></i></a>
            <a (click)="openSocialProfile('twitter')" class="btn-circle btn-twitter"><i class="zmdi zmdi-twitter"></i></a>
            <a (click)="openSocialProfile('youtube')" class="btn-circle btn-youtube"><i class="zmdi zmdi-youtube-play"></i></a><br>
            <a (click)="openSocialProfile('linkedin')" class="btn-circle btn-linkedin"><i class="zmdi zmdi-linkedin"></i></a>
            <a (click)="openSocialProfile('instagram')" class="btn-circle btn-instagram"><i class="zmdi zmdi-instagram"></i></a>
            <a (click)="openSocialProfile('github')" class="btn-circle btn-github"><i class="zmdi zmdi-github-alt"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</aside>
<footer class="ms-footer">
  <div class="container">
    <p>Copyright &copy; {{environment.company.nameFull}} {{currentYear}}</p>
  </div>
</footer>
<div class="btn-back-top">
  <a href="{{this.currentUrl}}" data-scroll id="back-top" class="btn-circle btn-circle-primary btn-circle-sm btn-circle-raised "><i class="zmdi zmdi-long-arrow-up"></i></a>
</div>
