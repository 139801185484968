<div id="ms-preload" class="ms-preload">
  <div id="status">
    <div class="spinner">
      <div class="dot1"></div>
      <div class="dot2"></div>
    </div>
  </div>
</div>
<div class="ms-site-container">
  <vt-account-modal></vt-account-modal>
  <vt-header></vt-header>
  <vt-nav-bar></vt-nav-bar>
  <router-outlet></router-outlet>
  <div class="clearfix"></div>
  <vt-footer></vt-footer>
</div>
<vt-side-menu></vt-side-menu>
