import {FormControl, ValidationErrors} from '@angular/forms';
// import {ValidatorService} from '../services/validator.service';

export class EmailValidator {
  // tslint:disable-next-line:max-line-length

  static emailValidator(control: FormControl): ValidationErrors | null {
    const emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const email: string = control.value;

    if (email) {
      const valid: boolean = emailRegex.test(email);
      if (valid === true) {
        return null;
      } else {
        return {'email': true};
      }
    } else {
      return {'email': true};
    }
  }
}
