import {Component, OnInit, PLATFORM_ID} from '@angular/core';
import {environment} from '../../../environments/environment';
import {NavigationEnd, Router} from "@angular/router";
import { Inject, Injectable } from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'vt-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  public environment: any;

  constructor(private router: Router,
              @Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private platformId: Object) {
    this.environment = environment;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && isPlatformBrowser(this.platformId)) {
        const allNavItems = this.document.querySelectorAll('#ms-navbar ul li a1');
        if (allNavItems) {
          for (let i = 0; i < allNavItems.length; i++) {
            const parent = allNavItems.item(i).parentElement;
            if (parent) {
              if (this.router.url.endsWith(allNavItems.item(i).getAttribute('routerLink') || '')) {
                parent.classList.add('active')
              } else {
                parent.classList.remove('active');
              }
            }
          }
        }
      }
    })
  }

  ngOnInit(): void {
  }

}
