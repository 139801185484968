<div class="ms-hero-page-override ms-hero-img-city ms-hero-bg-primary">
  <div class="container">
    <div class="text-center">
      <span class="center-block mb-2 mt-2 animated zoomInDown animation-delay-5"><img src="{{environment.businessMediaHref}}/images/logos/profile.png" height="120"></span>
      <h1 class="no-m ms-site-title color-white center-block ms-site-title-lg mt-2 animated zoomInDown animation-delay-5">Veteran<span>Technologies</span></h1>
      <!--      <p class="lead lead-lg color-white text-center center-block mt-2 mw-800 text-uppercase fw-300 animated fadeInUp animation-delay-7">Discover our projects and the <span class="color-warning">rigorous process</span> of creation. Our principles are creativity, design, experience and knowledge.</p>-->
    </div>
  </div>
</div>
<div class="container">
  <div class="card card-hero animated slideInUp animation-delay-8 mb-6">
    <div class="card-body">
      <h2 class="color-primary">About Us</h2>
      <div class="row">
        <div class="col-lg-6 text-justify">
          <p class="dropcaps">We are a Service Disabled Veteran Owned Small Business (SDVOSB) offering multiple services including:</p>
          <ul>
            <li>Web Development</li>
            <li>Mobile app development</li>
            <li>DevOps automation development and consulting</li>
            <li>Technology Consulting</li>
            <li>Cybersecurity Consulting</li>
            <li>AWS Cloud Consulting</li>
          </ul>
        </div>
        <div class="col-lg-6 text-justify">
          <p>We are happy to discuss any needs you may need and create a customized plan based on your needs. Reach out to us and let us create a customized plan for you!</p>
          <p>Check us out on <a href="https://fiverr.com/veterantechio">Fiverr</a> and <a href="https://www.upwork.com/freelancers/~01fa9d56ae99acd4ce">UpWork</a>!</p>
        </div>
      </div>
      <hr class="dotted">
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <div>
            <div class="text-center">
              <img src="{{environment.businessMediaHref}}/images/veteran_owned_business_logo_medium.png" height="200">
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding-top: 5em">
        <div class="col-xl-4 col-md-4">
          <div>
            <div class="text-center">
              <a href="https://www.credly.com/badges/ea4bc238-d02d-4324-857f-29afefa575c5/public_url" target="_blank">
                <img src="https://images.credly.com/size/680x680/images/0e284c3f-5164-4b21-8660-0d84737941bc/image.png" height="150">
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4">
          <div>
            <div class="text-center">
              <a href="https://www.credly.com/badges/52adb570-0d1f-4498-b769-3b5a4aae118e/public_url" target="_blank">
                <img src="https://images.credly.com/size/680x680/images/b9feab85-1a43-4f6c-99a5-631b88d5461b/image.png" height="150">
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4">
          <div>
            <div class="text-center">
              <a href="https://www.credly.com/badges/7702d1b3-3c2c-4856-84b8-fa8725ff90e2/public_url" target="_blank">
                <img src="https://images.credly.com/size/680x680/images/00634f82-b07f-4bbd-a6bb-53de397fc3a6/image.png" height="150">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding-top: 5em">
        <div class="col-xl-4 col-md-4">
          <div>
            <div class="text-center">
              <a href="https://www.credly.com/badges/e0e62509-e58a-491f-8047-6f936f359b2d/public_url" target="_blank">
                <img src="https://images.credly.com/size/680x680/images/e1fc05b2-959b-45a4-8d20-124b1df121fe/CompTIA_Network_2Bce.png" height="150">
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4">
          <div>
            <div class="text-center">
              <a href="https://www.credly.com/badges/3fb274f1-9cd2-4fbd-bc18-28b57ce89c61/public_url" target="_blank">
                <img src="https://images.credly.com/size/680x680/images/74790a75-8451-400a-8536-92d792c5184a/CompTIA_Security_2Bce.png" height="150">
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4">
          <div>
            <div class="text-center">
              <a href="https://www.credly.com/badges/ed8fda56-a119-47f5-a209-be5b85f4059d/public_url" target="_blank">
                <img src="https://images.credly.com/size/680x680/images/bc08972c-3c7d-4b99-82a0-c94bcca36674/Badges_v8-07_Practitioner.png" height="150">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding-top: 5em">
        <div class="col-xl-4 col-md-4">
          <div>
            <div class="text-center">
              <a href="https://www.credly.com/badges/0425206f-04b7-4831-9d5a-165d28655c65/public_url" target="_blank">
                <img src="https://images.credly.com/images/6fcae0c0-78b7-48c5-a414-5d21665b2250/DevSecOps-Essentials.png" height="150">
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4">
          <div>
            <div class="text-center">
              <a href="https://www.credly.com/badges/1aba627e-6e6a-49c1-8657-4e0526ce2644/public_url" target="_blank">
                <img src="https://images.credly.com/size/680x680/images/fb49de32-6a4c-4850-97cc-942b638ae4c3/Think-LIke-a-Hacker.png" height="150">
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4">
          <div>
            <div class="text-center">
              <a href="https://www.credly.com/badges/d8bceed5-b770-44ad-a7f5-461c51b0b4aa/public_url" target="_blank">
                <img src="https://images.credly.com/size/680x680/images/c1ca6570-bdc6-40e9-8992-722050788418/Security-_-Privacy-by-Design-Foundational.png" height="150">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding-top: 5em">
        <div class="col-xl-4 col-md-4">
          <div>
            <div class="text-center">
              <a href="https://www.credly.com/badges/f3e7df1d-5ce7-44ac-9d6a-1c65b48b5c94/public_url" target="_blank">
                <img src="https://images.credly.com/size/680x680/images/a972f054-be07-4845-85c7-95c8d11852f5/IBM-Agile-Explorer.png" height="150">
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4">
          <div>
            <div class="text-center">
              <a href="https://www.credly.com/badges/79f2f0d8-5dda-4252-9238-3af66efd5f10/public_url" target="_blank">
                <img src="https://images.credly.com/size/680x680/images/872635ec-aa7b-4449-80e5-e7f4579f84b5/image.png" height="150">
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4">
          <div>
            <div class="text-center">
              <a href="https://www.credly.com/badges/7a2d2afb-9396-427f-84cd-ad32e0df07eb/public_url" target="_blank">
                <img src="https://images.credly.com/size/680x680/images/8708411b-8347-4e3a-aa01-5a1d7502abfe/image.png" height="150">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding-top: 5em">
        <div class="col-xl-4 col-md-4">
          <div>
            <div class="text-center">

            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4">
          <div>
            <div class="text-center">
              <a href="https://www.credly.com/badges/767309f8-d06f-44a8-a5fb-fc4e2262a2ed/public_url" target="_blank">
                <img src="https://images.credly.com/size/680x680/images/82966826-6630-4768-80d4-6028b3fab414/image.png" height="150">
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4">
          <div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="wrap ms-hero-bg-danger ms-hero-img-airplane ms-bg-fixed mb-6 mt-4">
  <div class="container">
    <h2 class="text-center color-white no-mt mb-6 wow fadeInUp bold">Our Services</h2>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
        <div class="ms-icon-feature wow flipInX animation-delay-4">
          <div class="ms-icon-feature-icon">
            <span class="ms-icon ms-icon-lg ms-icon-inverse ms-icon-white"><i class="fa fa-cloud"></i></span>
          </div>
          <div class="ms-icon-feature-content">
            <h4 class="color-white bold">Cloud Consulting</h4>
            <p class="color-white">We can help you figure out how the cloud can help your business and development workflow!</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
        <div class="ms-icon-feature wow flipInX animation-delay-4">
          <div class="ms-icon-feature-icon">
            <span class="ms-icon ms-icon-lg ms-icon-inverse ms-icon-white"><i class="fa fa-desktop"></i></span>
          </div>
          <div class="ms-icon-feature-content">
            <h4 class="color-white bold">Web Development and SEO</h4>
            <p class="color-white">We can create customized websites featuring customized functionality for your specific needs! We can also help configure basic SEO for your website so it can rank as highly as possible in search engine results!</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
        <div class="ms-icon-feature wow flipInX animation-delay-4">
          <div class="ms-icon-feature-icon">
            <span class="ms-icon ms-icon-lg ms-icon-inverse ms-icon-white"><i class="fa fa-tablet"></i></span>
          </div>
          <div class="ms-icon-feature-content">
            <h4 class="color-white bold">Mobile and Tablet Apps</h4>
            <p class="color-white">Need a iOS or Android mobile app? We can help! Let us know what you need and we can create a customized app specific for your needs!</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 mb-2">
        <div class="ms-icon-feature wow flipInX animation-delay-4">
          <div class="ms-icon-feature-icon">
            <span class="ms-icon ms-icon-lg ms-icon-inverse ms-icon-white"><i class="fa fa-lock"></i></span>
          </div>
          <div class="ms-icon-feature-content">
            <h4 class="color-white bold">Cybersecurity Consulting</h4>
            <p class="color-white">Need help securing your IT infrastructure, website, or mobile apps? We can help you determine what would make the biggest impact to your business's security.</p>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="text-center">-->
    <!--      <a href="javascript:void(0)" class="btn btn-raised btn-info wow flipInX animation-delay-8"><i class="fa fa-space-shuttle"></i> I have a project</a>-->
    <!--      <a href="javascript:void(0)" class="btn btn-raised btn-warning wow flipInX animation-delay-9"><i class="fa fa-info"></i> More Info</a>-->
    <!--    </div>-->
  </div>
</div>
<div class="container">
  <h1 class="color-primary text-center wow fadeInUp animation-delay-4">Our Team</h1>
  <div class="row d-flex justify-content-center">
    <div class="col-lg-4 col-md-6">
      <div class="card card-warning wow zoomInUp animation-delay-5">
        <div class="withripple zoom-img">
          <img src="{{environment.baseAssetsHref}}/images/email_profile_image.jpg" alt="..." class="img-fluid">
        </div>
        <div class="card-body">
          <span class="badge badge-danger pull-right">Owner & Lead Software Engineer</span>
          <h3 class="color-primary">Corey Walker</h3>
          <p>
            Corey is the Owner of Veteran Technologies, LLC and a a U.S. Air Force veteran. He has been doing software
            and web development since 2007 and has been a professional software engineer since 2014.
          </p>
          <div class="row">
            <div class="col">
              <a href="https://facebook.com/vol4life85" target="_blank" class="btn-circle btn-circle-raised btn-circle-xs mt-1 mr-1 no-mr-md btn-facebook"><i class="zmdi zmdi-facebook"></i></a>
              <a href="https://twitter.com/vol4life85" target="_blank" class="btn-circle btn-circle-raised btn-circle-xs mt-1 mr-1 no-mr-md btn-twitter"><i class="zmdi zmdi-twitter"></i></a>
              <a href="https://instagram.com/vol4life85" target="_blank" class="btn-circle btn-circle-raised btn-circle-xs mt-1 mr-1 no-mr-md btn-instagram"><i class="zmdi zmdi-instagram"></i></a>
              <a href="https://github.com/vt-cwalker" target="_blank" class="btn-circle btn-circle-raised btn-circle-xs mt-1 mr-1 no-mr-md btn-github"><i class="zmdi zmdi-github"></i></a>
              <a href="https://www.linkedin.com/in/vol4life85" target="_blank" class="btn-circle btn-circle-raised btn-circle-xs mt-1 mr-1 no-mr-md btn-linkedin"><i class="zmdi zmdi-linkedin"></i></a>
            </div>
<!--            <div class="col text-right">-->
<!--              <a href="/about/corey" class="btn btn-raised btn-sm btn-danger"><i class="zmdi zmdi-account"></i> Profile</a>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div> <!-- container -->

<script type="text/javascript" src="https://cdn.credly.com/assets/utilities/embed.js" async></script>
