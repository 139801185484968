<header class="ms-header ms-header-white">
  <!--ms-header-white-->
  <div class="container container-full">
    <div class="ms-title">
      <a routerLink="/">
        <img src="{{environment.businessMediaHref}}/images/logos/full_no_buffer_transparent_bg_horizontal.png" alt="">
<!--        <span class="ms-logo animated zoomInDown animation-delay-5">VT</span>-->
<!--        <h1 class="animated fadeInRight animation-delay-6">Veteran <span>Technologies</span></h1>-->
      </a>
    </div>
    <div class="header-right">
      <div class="share-menu">
        <ul class="share-menu-list">
          <li class="animated fadeInRight animation-delay-2"><a class="btn-circle btn-facebook" (click)="share('facebook')"><i class="zmdi zmdi-facebook"></i></a></li>
          <li class="animated fadeInRight animation-delay-1"><a class="btn-circle btn-twitter" (click)="share('twitter')"><i class="zmdi zmdi-twitter"></i></a></li>
        </ul>
        <a href="javascript:void(0)" class="btn-circle btn-circle-primary animated zoomInDown animation-delay-7"><i class="zmdi zmdi-share"></i></a>
      </div>
<!--      <a href="javascript:void(0)" class="btn-circle btn-circle-primary no-focus animated zoomInDown animation-delay-8" data-toggle="modal" data-target="#ms-account-modal"><i class="zmdi zmdi-account"></i></a>-->
<!--      <form class="search-form animated zoomInDown animation-delay-9">-->
<!--        <input id="search-box" type="text" class="search-input" placeholder="Search..." name="q" />-->
<!--        <label for="search-box"><i class="zmdi zmdi-search"></i></label>-->
<!--      </form>-->
     <a href="javascript:void(0)" class="btn-ms-menu btn-circle btn-circle-primary ms-toggle-left animated zoomInDown animation-delay-10"><i class="zmdi zmdi-menu"></i></a>
    </div>
  </div>
</header>
